import React from "react";

const Res = () => {
  return (
    <>
     {/*  commercial Developmen */}
      <section id="commercial">
        <div className="container mb-2 ">
          <div className="row">
            <div className="col-12 ">
              <h2 className=" radiant mt-4 mb-4">New Commercial Development,<br />
                Offers For Purvanchal Royal Park  Resident</h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="carouselExampleCaptions1" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card3.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                Bhutani cyberthum <span className="mt-2"> Sector 140A,
                                  Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>Retails Shops, Office space and Foodcourt Connect for Best Deals
                              ...
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card4.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                M3M Cullinan <span className="mt-2"> Sector 94, Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>Modern Office Spaces with World Class Facilities. Tower B: G+15
                              Floors...
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card7.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                Fairfox EON <span className="mt-2">Sector 140, Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>High-end Office &amp; Retail Spaces Sector 140A, Noida By Eye Of
                              Noida
                              ...
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card8.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                ACE Group
                                <span className="mt-2"> Sector 153, Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>Modern Office Spaces with World Class Facilities. ACE Group
                              :Sector
                              153...</p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* More carousel items */}
          </div>
          {/* Previous Button */}
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>

          {/* Next Button */}
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      <section>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card phpto.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                GYGY Mentis <span className="mt-2 ">Sector 140 Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>GYGY Mentis offers office and retail space with assured return
                              and
                              lease ...
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card2.jpeg" alt="194x228" className=" w-100 im-fluid" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                CRC The Flagship
                                <span className="mt-2 "> Sector 140A, Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> Retail Shops And Commercial Office Spaces. The Best Integrated
                              IT/ITES Campus...</p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card6.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                                Saya Status Mall
                                <span className="mt-2 ">Sector 129 Noida</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> Modern Office Spaces with World Class Facilities. Saya Status
                              Mall Floors...
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/card5.jpeg" alt="194x228" className=" w-100 im-fluid" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <span class>
                              <h5 className="name">
                                <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a>
                                <a href>
                                  One FNG by Group 108
                                  <span className="mt-2 ">Sector 142 Noida</span>
                                </a>
                              </h5>
                            </span></div>
                          <div className="description">
                            <p>Modern Office Spaces with World Class Facilities. Sector-142:
                              G+15 Floors...</p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      {/* new slider */}
      <section className="mt-4 mb-2">

        <div id="carouselExampleCaptions2" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd"> FAIRFOX EON </div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">CRC The Flagship</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd"> ACE YXP</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">ACE 153</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Bhutani Avenue 133</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Bhutani Avenue 62</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Bhutani Grandthum</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Bhutani Cyberthum</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Artha Mart, Noida </div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Spectrum Metro Ph-2</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3 p-2" id="sd"> Iris Broadway Greno West</div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd">Orion One 32</div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd">Saya Status</div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd">Group 108 - Noida </div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd">Splendor Onyx Blue</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd"> Purvanchal Skyline Vista</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">GYGY Mentis</div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Mahagun Marina Walk</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd">Sikka Mall Of Expressway</div>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>









      </section>

      {/*  Residential Developmen */}
      <section id="residential">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2 className="radiant mt-5 mb-4">New Residential Development,<br />
                Offers For Purvanchal Royal Park  Resident</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-12 ">
              <div className="row">
                <div className="col-lg-6 col-12 mt-4 ">
                  <div className="card ">
                    <img src="assets/images/r1.jpeg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <span className="n"> <a href>
                        M3M The Cullinan | Sector 94, Noida
                      </a></span>
                      <p className="card-text mt-2">
                        This Luxury residential project offers you 4 &amp; 5 BHK Premium Apartments...</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-4  ">
                  <div className="card">
                    <img src="assets/images/r2.jpeg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <span className="n"> <a href>
                        Godrej Tropical | Isle Sector 146 Noida
                      </a></span>
                      <p className="card-text mt-2">
                        Godrej Tropical Isle offers 3 &amp; 4 BHK flats, Starting at INR 3.20 Cr. Onwards
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 mt-4 ">
                  <div className="card ">
                    <img src="assets/images/r3.jpeg" className="card-img-top " alt="..." />
                    <div className="card-body">
                      <span className="n"> <a href>
                        Kalpataru Vista | Sector 128 Noida
                      </a></span>
                      <p className="card-text mt-2">
                        Kalpataru Vista Noida - 3 &amp; 4 BHK Flats in Noida Sector 128 ...</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12  mt-4">
                  <div className="card ">
                    <img src="assets/images/r4.jpeg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <span className="n"> <a href>
                        Arihant One | Greater Noida West
                      </a></span>
                      <p className="card-text mt-2">
                        It's offers 3 &amp; 4 BHK Luxury Flats in Sector-1 Noida Extension.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item bg-info">
                      <h2 className="accordion-header " id="headingOne">
                      </h2>
                      <h6 className="text-center mt-3 mb-3 text-uppercase">
                        Offers &amp; Discount</h6>
                      <button onclick="myFunction()" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Residential, Sector-150 Noida
                      </button>
                      <ul className="list-group  text-start">
                        <li className="list-group-item">1. ATS Kingston Heath</li>
                        <li className="list-group-item"> 2. Ats Le Grandoise... <span id="hide" /></li>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <li className="list-group-item">3. Ats Pristine</li>
                            <li className="list-group-item"> 4. GODREJ NURTURE</li>
                            <li className="list-group-item"> 5. GODREJ SOLITAIRE</li>
                            <li className="list-group-item"> 6. Prateek Canary</li>
                            <li className="list-group-item"> 7. Max Antara</li>
                            <li className="list-group-item"> 8. Ace Parkway</li>
                            <li className="list-group-item">9. Samridhi Daksh Avenue</li>
                            <li className="list-group-item"> 10. Samridhi Luxuriya avenue</li>
                            <li className="list-group-item"> 11. Mahagun meadows</li>
                            <li className="list-group-item"> 12. Sethi venice</li>
                            <li className="list-group-item"> 13. Samridhi luxuriya Avenue</li>
                            <li className="list-group-item"> 14. Antariksh Golf Address</li>
                          </div>
                        </div>
                      </ul>
                    </div>
                    <div className="accordion-item mt-5">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Yamuna Expressway
                        </button>
                      </h2>
                      <ul className="list-group  text-start">
                        <li className="list-group-item">1. Supertech Golf Suites</li>
                        <li className="list-group-item">2. Solitairian City</li>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <li className="list-group-item"> 3. Gaurs Runway Suite</li>
                            <li className="list-group-item"> 4. Gaursons Krishna Villas</li>
                            <li className="list-group-item">5. Purvanchal royal city</li>
                            <li className="list-group-item">6. Nimbus Express Park View II</li>
                            <li className="list-group-item"> 7. ATS ALLURE</li>
                            <li className="list-group-item">8. Oasis Grand stand</li>
                            <li className="list-group-item"> 9. Home &amp; Soul Beetle Lap</li>
                          </div>
                        </div>
                      </ul></div>
                    <div className="accordion-item mt-5 ">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Noida Extension
                        </button>
                      </h2>
                      <ul className="list-group  text-start">
                        <li className="list-group-item"> 1. Ace City</li>
                        <li className="list-group-item">2. Fusion Homes</li>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <li className="list-group-item">3. Gaur Saundaryam</li>
                            <li className="list-group-item"> 4.SKA Greenarch</li>
                            <li className="list-group-item"> 5. PKS Central</li>
                            <li className="list-group-item"> 6. Himalya pride</li>
                            <li className="list-group-item"> 7. SKA Divya</li>
                            <li className="list-group-item"> 8. UNISEX SALON</li>
                            <li className="list-group-item"> 9. Apex Alphabet</li>
                            <li className="list-group-item"> 10.Arihant Arden</li>
                            <li className="list-group-item"> 11. Trident embassy</li>
                            <li className="list-group-item"> 12. Paramount Emotion</li>
                            <li className="list-group-item"> 13. Exotica Dreamville</li>
                          </div>
                        </div>
                      </ul></div>
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <div className="accordion" id="accordionExample2">
                    <div className="accordion-item bg-info">
                      <h6 className="accordion-header" id="heading2">
                      </h6><h5 className=" mt-3 mb-3  text-center text-uppercase">
                        Offers &amp; Discount</h5>
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                        Commercial, Noida
                      </button>
                      <ul className="list-group  text-start">
                        <li className="list-group-item"> 1. Ansal Api Corporate park
                        </li>
                        <li className="list-group-item">2. Eon Eye of Noida</li>
                        <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample2" style={{}}>
                          <div className="accordion-body">
                            <li className="list-group-item"> 3. Supernova Astralis</li>
                            <li className="list-group-item"> 4. ATS Bouquet</li>
                            <li className="list-group-item"> 5. Ace Medley Avenue</li>
                            <li className="list-group-item"> 6. Paras ONE33</li>
                            <li className="list-group-item"> 7. Express Trade Tower 2 </li>
                            <li className="list-group-item"> 8. Urbtech Trade Centre</li>
                            <li className="list-group-item"> 9. Antriksh Golf City </li>
                            <li className="list-group-item"> 10. Krasa Centrade Business Park </li>
                            <li className="list-group-item"> 11. Urbtech NPS </li>
                            <li className="list-group-item">12. ATS Kabana High </li>
                            <li className="list-group-item"> 13. JMS Crosswalk </li>
                            <li className="list-group-item"> 14. Marine Square </li>
                            <li className="list-group-item"> 15. Emaar Colonnade </li>
                            <li className="list-group-item"> 16. Imperia Aashiyara </li>
                            <li className="list-group-item"> 17. Milestone Experion Centre </li>
                            <li className="list-group-item">18. Signature Global Signum </li>
                            <li className="list-group-item">19. MRG The ultimus </li>
                            <li className="list-group-item"> 20. Spaze Tristaar </li>
                            <li className="list-group-item"> 21. Suncity Trade Tower </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* new slider2 */}
      <section className="mt-5 mb-5">
        <div id="carouselExampleCaptions4" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> Ace Terra, Sector 22D Yamuna
                    Expressway, </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">SKA Destiny One, Sector ZETA 1 Greater
                    Noida</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Kalpataru Vista, Sector 128 Noida</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Irish Platinum, Greater Noida West</div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Sublime Spring Elmas, Greater Noida West
                  </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Elite X, Sector 10 Greater Noida West
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Stellar One Phase 2, Noida Extension
                  </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> M3M The Cullinan, Sector 94 Noida</div>
                  <div className="col-lg-3 col-12 mt-3 p-2" id="sd2"> Experion Elements, Sector 45 Noida</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3 p-2 " id="sd2">ATS Homekraft Pious Orchards, Sector 150
                    Noida</div>
                  <div className="col-lg-4 col-12 mt-3 p-2 " id="sd2">ATS Pious Hideaways, Sector 150 Noida
                  </div>
                  <div className="col-lg-3 col-12 mt-3 p-2 " id="sd2"> Max Estates 128, Sector 128 Noida</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Dasnac Burj Noida, Sector 75 Noida</div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Catalyst Express Apartments, Sector 127
                    Noida</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> M3M The Line, Sector 72 Noida</div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Equity Hardik Tower 101, Sector 101
                    Noida </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Nirala Trio, Dadri, Greater Noida</div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Equity Soni Hardik 101, Sector 101 Noida
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> L&amp;T Sector 128 Noida</div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> Samridhi Daksh Avenue, Sector 150 Noida
                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Mahagun Manorialle, Sector 128 Noida
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3 p-2 " id="sd2">Prateek Canary, Sector 150 Noida</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> Purvanchal Skyline Vista</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">SKA Orion, Sector 143 Noida </div>
                </div>
              </div>
            </div>


          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>


      </section>
    </>

  );
}

export default Res;